<template>
  <v-container
    v-if="$isEnabledProperty($COMPRAR_ELEMENTOS_ADUANA)"
    id="crud"
    fluid
    tag="section"
  >
    <v-row
      ><v-col cols="auto" md="8" class="">
        <v-data-table
          :headers="headers"
          :items="productos"
          :search="search"
          sort-by="secret"
          class="elevation-4 data__table"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="$isEnabledProperty($COMPRAR_ELEMENTOS_ADUANA)"
              small
              :color="$cv('btnEditar')"
              title="Agregar"
              @click="AgregarProductos(item)"
            >
              mdi-plus-box
            </v-icon>
          </template>
          <template v-slot:top>
            <v-row class="pt-3 px-8" v-if="sourceVentaElementosAduanaSt">
              <v-col>
                <div>
                  <ClienteAduana
                    :cliente="clienteElementosAduana"
                    @cliente-seleccionado="actualizarCliente"
                    @update:cliente="clienteElementosAduana = $event"
                  ></ClienteAduana>
                </div>
              </v-col>
            </v-row>
            <v-toolbar flat color="white" class="pr-2">
              <v-row>
                <v-col sm="12" class="pt-10">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="'Busqueda de ' + title"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
        <AgregarProductos
          v-if="dialog"
          :item="editedItem"
          :action="accionProducto"
          @terminar="dialog = !dialog"
        />
      </v-col>
      <Carrito @editarCarrito="editProductos" />
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="!sourceVentaElementosAduana"
          color="primary"
          @click="$router.push('/')"
          >Atrás</v-btn
        >
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Carrito from "@/views/dashboard/components/secundarios/Carrito.vue";
import AgregarProductos from "@/views/dashboard/components/secundarios/AgregarProductos.vue";
import ClienteAduana from "@/views/dashboard/components/secundarios/ClienteAduana.vue";

function title() {
  return "Elementos ADUANA";
}

export default {
  components: {
    Carrito,
    AgregarProductos,
    ClienteAduana,
  },

  data: (vm) => ({
    accionProducto: "",
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
    search: "",
    valid: true,
    editedItem: {
      nombre: "",
      id: "",
    },
    defaultItem: {
      nombre: "",
      id: "",
    },
    keyTableAutorizados: 0,
    dialog: false,
    readonly: true,
    readOnlySubtotal: true,
    headers: [
      { text: "Código", filterable: true, value: "codigo" },
      { text: "Producto", filterable: true, value: "nombre" },
      { text: "P. Unitario", filterable: true, value: "precio" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    productos: [
      {
        id: 1,
        codigo: "01",
        nombre: "TOKEN CONCESIONARIOS",
        precio: 100,
      },
      {
        id: 2,
        codigo: "03",
        nombre: "DIGITAL AUTO (PRENDA)",
        precio: 50,
      },
      {
        id: 3,
        codigo: "08D",
        nombre: "AUTO TRANSFERENCIA DIGITAL",
        precio: 150,
      },
      {
        id: 4,
        codigo: "04",
        nombre: "MOTO TRAMITES VS",
        precio: 100,
      },
      {
        id: 5,
        codigo: "08",
        nombre: "MOTO TRANSFERENCIA",
        precio: 120,
      },
    ],
    clienteElementosAduana: "",
  }),

  created() {
    if (this.$route.path === "/elementos_aduana_compra") {
      this.resetSourceVentaElementosAduana();
    }
    if (
      !this.$route.params.cliente ||
      !this.$route.params.sourceVentaElementosAduana
    ) {
      this.clienteElementosAduana = this.clienteLocal;
      this.sourceVentaElementosAduana = this.sourceVentaElementosAduanaSt;
      this.actualizarSourceVentaElementosAduana(
        this.sourceVentaElementosAduanaSt
      );
      this.actualizarClienteLocal(this.clienteLocal);
    } else {
      this.clienteElementosAduana = this.$route.params.cliente;
      this.sourceVentaElementosAduana =
        this.$route.params.sourceVentaElementosAduana;
      this.actualizarSourceVentaElementosAduana(
        this.$route.params.sourceVentaElementosAduana
      );
      this.actualizarClienteLocal(this.$route.params.cliente);
    }
  },

  mounted() {
    console.log("Componente elementos aduana compra creado");
    this.$store.commit("SET_CART_EDIT_PATH", "/elementos_aduana_compra");
    //this.fetchProductos();
  },

  computed: {
    ...mapState("carrito", ["carrito", "total"]),
    ...mapState("aduana", ["clienteLocal", "sourceVentaElementosAduanaSt"]),
  },

  methods: {
    ...mapMutations("carrito", ["editProducto"]),
    ...mapActions("aduana", [
      "actualizarSourceVentaElementosAduana",
      "actualizarClienteLocal",
    ]),
    ...mapMutations("aduana", ["resetSourceVentaElementosAduana"]),

    AgregarProductos(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.subtotal = 0;
      this.accionProducto = "AGREGAR PRODUCTO";
      this.dialog = true;
    },
    editProductos(item) {
      this.editedItem = Object.assign({}, item);
      this.accionProducto = "EDITAR PRODUCTO";
      this.dialog = true;
    },
    actualizarCliente(clienteSeleccionado) {
      this.clienteElementosAduana = clienteSeleccionado;
    },
  },
};
</script>

<style>
.data__table {
  overflow-y: auto;
  max-height: 50.75rem;
}
</style>
